import React from 'react';

import { AuthContext } from '@providers/auth';
import ListNav from '../nav-list';
import UserDetail from '../user-detail';

export const AccoutNav = ({ auth }: { auth: AuthContext }) => {
  const navItems = [
    {
      label: 'ICON Admin',
      icon: 'logo',
      selected: false,
      link: '/magma-data',
    },
    {
      label: 'Sign Out',
      icon: 'right',
      selected: false,
      link: auth.logout,
    },
  ];

  return (
    <>
      {auth.user && <UserDetail user={auth.user} />}
      <ListNav items={navItems} />
    </>
  );
};

export default AccoutNav;
