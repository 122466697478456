import React from 'react';
import { Router, RouteComponentProps, Redirect } from '@reach/router';

import ApolloProvider from '@providers/apollo';

import Projects from '@/admin/projects';
import MagmaData from '@/admin/magma-data';
import PrintEventData from '@/admin/print-events';
import Codes from '@/admin/codes';
import Admixtures from '@/admin/admixtures';
import QCData from '@/admin/qc-data';
import Misc from '@/admin/misc';
import 'prismjs';

const Route = ({
  component,
}: RouteComponentProps & { component: React.ReactElement }) => component;

export default function Routes() {
  return (
    <ApolloProvider>
      <Router>
        <Route path="/projects/*" component={<Projects />} />
        <Route path="/magma-data/*" component={<MagmaData />} />
        <Route path="/qc-data/*" component={<QCData />} />
        <Route path="/admixtures/*" component={<Admixtures />} />
        <Route path="/print-event-data/*" component={<PrintEventData />} />
        <Route path="/codes/*" component={<Codes />} />
        <Route path="/misc/*" component={<Misc />} />
        <Route default component={<Redirect to="/magma-data" />} />
      </Router>
    </ApolloProvider>
  );
}
