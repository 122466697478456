import React from 'react';
import App from '@templates/app';

const ProjectList = () => {
  return (
    <App section="projects" title="Projects">
      <div className="flex flex-col items-center justify-center">
        Projects List has been moved to Studio <br />
        <a
          href="https://studio.iconbuild.com"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          https://studio.iconbuild.com/designs
        </a>
      </div>
    </App>
  );
};

export default ProjectList;
